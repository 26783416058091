<template>
    <el-dialog
        title="可贷点识别（包括客户家人和朋友）"
        :visible="showQuality"
        width="500px"
        :before-close="closeQuality"
        center
    >
        <div>
            <!-- 普通 -->
            <div class="normal">普通可贷点：</div>
            <div class="content">
                <div class="content-item">
                    <div class="content-num">1</div>
                    <div class="content-info">有银行代发工资5000以上</div>
                </div>
                <div class="content-item">
                    <div class="content-num">2</div>
                    <div class="content-info">在本地（杭州）有社保</div>
                </div>
                <div class="content-item">
                    <div class="content-num">3</div>
                    <div class="content-info">在本地（杭州）有公积金</div>
                </div>
                <div class="content-item">
                    <div class="content-num">4</div>
                    <div class="content-info">名下有车辆，年限在10年内，非运营车、公司车、货车</div>
                </div>
                <div class="content-item">
                    <div class="content-num">5</div>
                    <div class="content-info">在本地（杭州）执照满1年以上</div>
                </div>
                <div class="content-item">
                    <div class="content-num">6</div>
                    <div class="content-info">有保单年缴2500以上</div>
                </div>
                <div class="content-item">
                    <div class="content-num">7</div>
                    <div class="content-info">有微粒贷5000以上</div>
                </div>
                <div class="content-item">
                    <div class="content-num">8</div>
                    <div class="content-info">名下有回迁安置房</div>
                </div>
            </div>
            <div class="excellent">优质可贷点：</div>
            <div class="content">
                <div class="content-item">
                    <div class="content-num">1</div>
                    <div class="content-info">有公积金双边1000以上</div>
                </div>
                <div class="content-item">
                    <div class="content-num">2</div>
                    <div class="content-info">外地人社保1年，本地人社保满半年</div>
                </div>
                <div class="content-item" style="height:60px">
                    <div class="content-num" style="line-height:60px">3</div>
                    <div
                        class="content-info"
                        style="line-height: 29px;"
                    >名下有全款房、按揭房、公寓、厂房、排屋、别墅、商铺（非自建房、无产证的房子）</div>
                </div>
                <div class="content-item" style="height:60px">
                    <div class="content-num" style="line-height:60px">4</div>
                    <div
                        class="content-info"
                        style="line-height: 29px;"
                    >名下有车，裸车价20万以上，年限在3年内，非运营车、公司车、货车</div>
                </div>
                <div class="content-item" style="height:60px">
                    <div class="content-num" style="line-height:60px">5</div>
                    <div
                        class="content-info"
                        style="line-height: 29px;"
                    >优质工作单位：公务员、事业单位、知名大企业、世界500强、中国500强、上市公司、国企、垄断行业</div>
                </div>
                <div class="content-item">
                    <div class="content-num">6</div>
                    <div class="content-info">全国公司法人或者大股东，年缴税2万以上，缴税满2年</div>
                </div>
                <div class="content-item">
                    <div class="content-num">7</div>
                    <div class="content-info">在杭州做生意（有营业执照），季度结息28元以上</div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            showQuality: false,
        };
    },
    methods: {
        init() {
            this.showQuality = true;
        },
        closeQuality() {
            this.showQuality = false;
        },
    },
};
</script>

<style lang="less" scoped>
.normal,
.excellent {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}
.content {
    border: 1px solid #aaa;
}
.content-item {
    display: flex;
    align-items: center;
    // height: 100%;
    height: 40px;
    // border-bottom: 1px solid #aaa;
    .content-num {
        width: 50px;
        // height: 40px;
        line-height: 40px;
        height: 100%;
        text-align: center;
        border-right: 1px solid #aaa;
    }
    .content-info {
        height: 100%;
        margin-left: 10px;
        vertical-align: middle;

        // height: 40px;
        width: 100%;
        line-height: 40px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid #aaa;
    }
}
</style>
