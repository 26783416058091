import { render, staticRenderFns } from "./quality-info.vue?vue&type=template&id=763de78a&scoped=true&"
import script from "./quality-info.vue?vue&type=script&lang=js&"
export * from "./quality-info.vue?vue&type=script&lang=js&"
import style0 from "./quality-info.vue?vue&type=style&index=0&id=763de78a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763de78a",
  null
  
)

export default component.exports